<template>
    <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="6">
        <vs-card>
        <div slot="header">
            <h3>
            Insert New Event
            </h3>
        </div>
        <div>
            <vs-input label="Time" type="time" v-model="time"/>
            &nbsp;Status
            <div style="width: 48%">
                <v-select
                    v-model="status"
                    :options="statii"
                ></v-select>
            </div>
            
        </div>
        <div slot="footer">
            <vs-row vs-justify="flex-end">
            <vs-button 
            type="gradient" 
            color="danger" 
            icon="add" @click="insertEvent"
            :disabled="disabled === true"
            ></vs-button>
                                      
            </vs-row>
        </div>
        </vs-card>
    </vs-col>
    </vs-row>
</template>

<script>
import vSelect from "vue-select";
export default {
    data(){
        return {
            statii: [
                'started', 'paused', 'resumed', 'stopped'
            ],
            status: 'started',
            time: '',
            
        }
    },
    props: ["user_id", "disabled"],
    components: {
        "v-select": vSelect
    },
    methods: {
        insertEvent: function() {
            if (this.time === "") {
                alert('Please Select Time');
                return;
            }
            
            this.$emit('insertEvent', {time: this.time, status: this.status, user_id: this.user_id})
        }
    }
}
</script>

<style>

</style>