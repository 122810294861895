<template>
  <div>
    <vs-row>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
        <datepicker
          placeholder="Select Date"
          :format="customFormatter"
          v-model="startDate"
        ></datepicker>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="start" vs-align="start" vs-w="3">
        <vs-select
          class="selectExample"
          v-model="teamV_mmodel"
          placeholder="Select Spoc Team"
        >
          <vs-select-item
            :key="index"
            :value="item.text"
            :text="item.text"
            v-for="(item, index) in team"
          />
        </vs-select>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="start" vs-align="start" vs-w="2">
        <vs-button
          color="primary"
          type="filled"
          @click="getusers()"
          style="top: 10px"
          >Get Data</vs-button
        >
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="5"
      ></vs-col>
    </vs-row>
    <vs-row  >
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <vs-card v-if="users.length > 0">
          <vs-row>
            <vs-col
              vs-type="flex"
              vs-justify="start"
              vs-align="start"
              vs-w="12"
            >
              <h3>
                {{ selectedTeam }} - {{ customFormatter(startDate) }}
              </h3>
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col
              style="display: initial"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
            >
              <div>
                <vs-collapse accordion>
                  <vs-collapse-item v-for="item in users" :key="item.user_id">
                    <div slot="header">
                      <vs-row>
                        <vs-col
                          vs-type="flex"
                          vs-justify="start"
                          vs-align="start"
                          vs-w="2"
                        >
                          <h6>{{ item.name }}</h6>
                        </vs-col>

                        <vs-col
                          v-if="isToday"
                          vs-type="flex"
                          vs-justify="start"
                          vs-align="start"
                          vs-w="2"
                        >
                          <div class="tooltip">
                            <span>
                              <h6>
                                <vs-icon
                                  style="top: 2px; position: relative"
                                  icon="work"
                                  :color="
                                    getWorkStatusColours(item.work_status)
                                  "
                                ></vs-icon>
                                {{ getWorkStatusText(item.work_status) }}
                              </h6>
                            </span>
                            <span class="tooltiptext">
                              <small>Work status</small>
                            </span>
                          </div>
                        </vs-col>
                        <vs-col
                          vs-type="flex"
                          vs-justify="start"
                          vs-align="start"
                          vs-w="2"
                        >
                          <div class="tooltip">
                            <span>
                              <h6>
                                <vs-icon
                                  style="top: 2px; position: relative"
                                  icon="account_box"
                                  :color="
                                    getAttendanceColours(item.attendance.status)
                                  "
                                ></vs-icon>
                                {{ getAttendanceText(item.attendance.status) }}
                              </h6>
                            </span>
                            <span class="tooltiptext">
                              <small>Attendance</small>
                            </span>
                          </div>
                        </vs-col>
                        <vs-col
                          vs-type="flex"
                          vs-justify="start"
                          vs-align="start"
                          vs-w="2"
                        >
                          <div class="tooltip">
                            <span>
                              <h6>
                                <vs-icon
                                  style="top: 2px; position: relative"
                                  icon="power"
                                  color="primary"
                                ></vs-icon>
                                Login:
                                {{ getHumanTime(item.attendance.login_time) }}
                              </h6>
                            </span>
                            <span class="tooltiptext">
                              <small>Login Time</small>
                            </span>
                          </div>
                        </vs-col>
                        <vs-col
                          vs-type="flex"
                          vs-justify="start"
                          vs-align="start"
                          vs-w="2"
                        >
                          <div class="tooltip">
                            <span>
                              <h6>
                                <vs-icon
                                  style="top: 2px; position: relative"
                                  icon="power_off"
                                  color="black"
                                ></vs-icon>
                                Logout:
                                {{ getHumanTime(item.attendance.logout_time) }}
                              </h6>
                            </span>
                            <span class="tooltiptext">
                              <small>Logout Time</small>
                            </span>
                          </div>
                        </vs-col>
                        <vs-col
                          vs-type="flex"
                          vs-justify="start"
                          vs-align="start"
                          vs-w="2"
                        >
                          <div class="tooltip">
                            <h6>
                              <vs-icon
                                style="top: 2px; position: relative"
                                icon="watch_later"
                                color="dark"
                              ></vs-icon>
                              Hours :
                              {{ getRoundedTime(item.attendance.hours_logged) }}
                            </h6>
                            <span class="tooltiptext">
                              <small>Hours Logged</small>
                            </span>
                          </div>
                        </vs-col>
                      </vs-row>
                    </div>
                    <div>
                      <vs-row>
                        <vs-col
                          vs-type="flex"
                          vs-justify="start"
                          vs-align="start"
                          vs-w="12"
                        >
                          <vs-card>
                            <vs-list>
                              <vs-list-item
                                v-for="event in item.time_tracking"
                                :key="event.id"
                                :icon="getEventIcon(event.status)"
                                :title="getHumanTime(event.time)"
                                :subtitle="getEventText(event)"
                              ></vs-list-item>
                            </vs-list>
                            <vs-divider />

                            <eventInserter
                              :user_id="item.user_id"
                              :disabled="disabled"
                              @insertEvent="insertEvent"
                            ></eventInserter>
                          </vs-card>
                        </vs-col>
                      </vs-row>
                    </div>
                  </vs-collapse-item>
                </vs-collapse>
              </div>
            </vs-col>
          </vs-row>
          <vs-row style="margin-top: 3%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="12">
          <vs-chip color="primary" style="margin-right: 2%">
            <b>{{ countsleads }}</b>
          </vs-chip>
          <vs-pagination :total="tablelinks" v-model="currentTablePage"></vs-pagination>
        </vs-col>
      </vs-row>
        </vs-card>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import axios from "axios";
import constants from "../../constants.json";
import _ from "lodash";
import eventInserter from "../components/attendance/insertEvent";
export default {
  data() {
    return {
      countsleads: 0,
      tablelinks: 0,
      currentTablePage: 1,
      startDate: null,
      selectedTeam: "",
      users: [],
      userdata: [],
      teamV_mmodel: "",
      teamList: [],
      team: [],
      teamName: "",
      isToday: true,
      disabled: false,
    };
  },
  watch: {
    startDate(value) {
      this.startDate = value;
      const start = moment(this.startDate).startOf("day").unix();
      const today = moment().startOf("day").unix();
      if (start === today) {
        this.isToday = true;
      } else {
        this.isToday = false;
      }
      this.users = [];
    },
    teamV_mmodel(value) {
      // this.currentTablePage = 1;
      this.teamName = value;
      console.log("teamName", this.teamName);
      console.log("date", this.startDate);
      this.users = [];
    },
    currentTablePage(val) {
        // console.log("current page", val);
        this.getusers(val);
    },
  },
  computed: {},
  mounted() {
    this.getTeams();
    // this.getusers();
    
    this.startDate = new Date();
  },
  methods: {
    getWorkStatusColours(status) {
      let colour = "";
      switch (status) {
        case "started":
          colour = "success";
          break;
        case "paused":
          colour = "warning";
          break;
        case "resumed":
          colour = "success";
          break;
        case "stopped":
          colour = "danger";
          break;
        default:
          break;
      }
      return colour;
    },
    getWorkStatusText(status) {
      console.log("status", status)
      let text = "";
      switch (status) {
        case "started":
          text = "Started";
          break;
        case "paused":
          text = "Paused";
          break;
        case "resumed":
          text = "Resumed";
          break;
        case "stopped":
          text = "Stopped";
          break;
        default:
          break;
      }
      return text;
    },
    getAttendanceColours(status) {
      let colour = "";
      switch (status) {
        case "present":
          colour = "success";
          break;
        case "came_late":
          colour = "warning";
          break;
        case "left_early":
          colour = "warning";
          break;
        case "absent":
          colour = "danger";
          break;
        default:
          break;
      }
      return colour;
    },
    getAttendanceText(status) {
      let text = "";
      switch (status) {
        case "present":
          text = "Present";
          break;
        case "came_late":
          text = "Came Late";
          break;
        case "left_early":
          text = "Left Early";
          break;
        case "absent":
          text = "Absent";
          break;
        default:
          break;
      }
      return text;
    },
    customFormatter(date) {
      return moment(date).format("Do MMMM YYYY");
    },
    getHumanTime(timestamp) {
      if (timestamp > 0) {
        return moment.unix(timestamp).format("h:mm a");
      }
      return "-";
    },
    getRoundedTime(hours_logged) {
      return Math.round(hours_logged * 10) / 10;
    },
    getEventIcon(status) {
      let icon = "stop";
      switch (status) {
        case "started":
          icon = "play_circle_filled";
          break;
        case "paused":
          icon = "pause_circle_filled";
          break;
        case "resumed":
          icon = "play_circle_filled";
          break;
        case "stopped":
          icon = "stop";
          break;
        default:
          break;
      }
      return icon;
    },
    getEventText(event) {
      let text = "";
      switch (event.status) {
        case "started":
          text = "Started";
          break;
        case "paused":
          text = "Paused";
          break;
        case "resumed":
          text = "Resumed";
          break;
        case "stopped":
          text = "Stopped";
          break;
        default:
          break;
      }
      if (event.inserted_by_name !== null) {
        text += `, Inserted By: ${event.inserted_by_name}`;
      }
      return text;
    },
    //get Teams
    getTeams() {
      this.team = [];
      let url = `${constants.SERVER_API}team`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("team list", response.data.data);
          this.teamList = response.data.data;
          this.teamList.forEach((element) => {
            this.team.push({
              text: element.name,
              id: element.id,
            });
          });
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getusers(val) {
      this.$vs.loading();
      this.users = [];
      this.selectedTeam = this.teamName;
      let obj = {
        page: val,
        team: this.teamName,
        attendance_date: moment(this.startDate)
          .startOf("day")
          .unix()
          .toString(),
      };
      console.log("date:", obj);
      //axios
      let url = `${constants.SERVER_API}getUserStatus`;

      axios
        .get(url, {
          params: obj,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("user response", response.data.user_time_tracking.data);
          this.$vs.loading.close();
          this.countsleads = response.data.user_time_tracking.total;
          if (response.status === 200) {
            let user_statii = response.data.user_time_tracking.data;
            for (let i = 0; i < user_statii.length; i++) {
              const user_status = user_statii[i];
              user_status.time_tracking = _.sortBy(
                user_status.time_tracking,
                function (event) {
                  return event.time;
                }
              );
              const attendance_data = this.getAttendanceData(
                user_status.time_tracking
              );
              const user = {
                name: user_status.name,
                team: user_status.team,
                work_status: user_status.working_status,
                time_tracking: user_status.time_tracking,
                user_id: user_status.user_id,
                attendance: attendance_data,
              };
              this.users.push(user);
            }
            this.currentTablePage = response.data.user_time_tracking.current_page;
            this.tablelinks = response.data.user_time_tracking.last_page;
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getAttendanceData(events) {
      let attendance_data = {
        login_time: null,
        logout_time: null,
        hours_logged: 0,
        status: "absent",
      };

      if (events.length === 0) {
        return attendance_data;
      }
      let time_logged = 0;
      const start = moment(this.startDate).startOf("day").unix();
      let end = moment(this.startDate).endOf("day").unix();

      const today = moment().startOf("day").unix();
      if (start === today) {
        end = moment().unix();
      }

      // Allowed in time for marking came late: 10.30 am = 10.5 hrs from midnight
      const in_time_limit = start + 10.5 * 3600;

      // Allowed out time for marking left early: 5.30 pm = 17.5 hrs from midnight
      const out_time_limit = start + 17.5 * 3600;

      let current_status = "not_working";
      if (events[0].status !== "started" && events[0].status !== "resumed") {
        current_status = "working";
      }

      let previous_timestamp = start;
      let login_time_recorded = false;

      for (let i = 0; i < events.length; i++) {
        const event = events[i];

        let time_chunk = event.time - previous_timestamp;
        previous_timestamp = event.time;
        if (current_status === "working") {
          time_logged += time_chunk;
        }

        if (event.status === "paused" || event.status === "stopped") {
          current_status = "not_working";
        }
        if (event.status === "started" || event.status === "resumed") {
          current_status = "working";
        }
        // record login time
        if (event.status === "started" && login_time_recorded === false) {
          attendance_data.login_time = event.time;
          login_time_recorded = true;
        }
      }
      if (current_status === "working") {
        let last_action = events[events.length - 1];
        let last_time_chunk = end - last_action.time;
        time_logged += last_time_chunk;
      } else {
        // Record logout time
        attendance_data.logout_time = events[events.length - 1].time;
      }
      let hours_logged = time_logged / 3600;
      attendance_data.hours_logged = hours_logged;

      attendance_data.status = "present";

      for (let i = 0; i < events.length; i++) {
        const event = events[i];
        if (event.status === "started") {
          if (event.time > in_time_limit) {
            attendance_data.status = "came_late";
          }
          break;
        }
      }
      if (
        events[events.length - 1].status === "stopped" &&
        events[events.length - 1].time < out_time_limit
      ) {
        attendance_data.status = "left_early";
      }
      return attendance_data;
    },
    insertEvent(newEvent) {
      newEvent.timestamp = this.getTimestampFromTime(newEvent.time);
      const url = `${constants.MILES_CM_BACK}insertWorkStatusEvent`;
      console.log("attendence data", url, newEvent)
      this.disabled = true;
      axios
        .post(url, newEvent, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          if (response.data.status === "success") {
            console.log(response);
            // Replace current user's data with data from response
            for (let i = 0; i < this.users.length; i++) {
              const user = this.users[i];
              if (user.user_id === response.data.event.user_id) {
                user.time_tracking.push(response.data.event);
                user.time_tracking = _.sortBy(user.time_tracking, function (
                  event
                ) {
                  return event.time;
                });
                user.attendance = this.getAttendanceData(user.time_tracking);
                this.disabled = false;
                this.$vs.notify({
                  title: "Record Insertion Complete",
                  text: `Inserted work status record for ${user.name}`,
                  color: "success",
                });
                break;
              }
            }
          } else {
            this.$vs.notify({
              title: "Duplicate record",
              text: response.data.message,
              color: "warning",
            });
            this.disabled = false;
          }
        })
        .catch((error) => {
          this.disabled = false;
          this.handleError(error);
        });
    },

    getTimestampFromTime(timeString) {
      const start = moment(this.startDate).startOf("day").unix();
      const timeArray = timeString.split(":");
      const seconds_form_hours = timeArray[0] * 60 * 60;
      const seconds_from_minutes = timeArray[1] * 60;
      return start + seconds_form_hours + seconds_from_minutes;
    },
  },
  components: {
    Datepicker,
    eventInserter,
  },
};
</script>

<style lang="scss">
</style>
<style>
.vs-popup {
  max-width: 800px;
}
.reportrange-text {
  border-radius: 5px;
}
.gaps {
  margin-bottom: 4%;
}
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: fit-content;
  background-color: gray;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1000;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
}
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: 0px;
  border-width: 5px;
  border-style: solid;
  border-color: gray transparent transparent transparent;
}
.heading {
  color: gray;
  font-size: 15px;
  font-weight: bold;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
div.vs-popup {
  width: 300px;
}
.vdp-datepicker__calendar {
  z-index: 2000;
}
.vs-popup--title {
  justify-content: center;
  display: flex;
}
.tableHeader {
  border-radius: 5px;
  display: flex;
  margin: auto;
  width: 100%;
  background: #f2f2f2;
  height: 40px;
}
.popcontentrow {
  display: flex;
  width: 100%;
  border: 2px solid #f2f2f2;
  border-radius: 5px;
}
</style>